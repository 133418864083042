import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ArrowButton from 'src/components/button/ArrowButton';
import './Timetable.scss';
import { v4 as uuid } from 'uuid';
import { getHourDifference, extractHoursFromText } from 'src/components/util/time';
import tinycolor from 'tinycolor2';

const days = {
  MONDAY: 0,
  TUESDAY: 1,
  WEDNESDAY: 2,
  THURSDAY: 3,
  FRIDAY: 4,
  SATURDAY: 5,
  SUNDAY: 6,
};

const dayTranslations = {
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
};

const coursesQuery = graphql`
  query {
    allCoursesJson {
      edges {
        node {
          name
          background
          color
          location
          start
          stop
          day
          id
          width
          offset
          zindex
        }
      }
    }
  }
`;

const hours = [];
const lowerLimitHour = 10;
const upperLimitHour = 22;
const totalHours = upperLimitHour - lowerLimitHour;
const scaler = 100.00 / totalHours;

for (let hour = lowerLimitHour; hour <= upperLimitHour; hour++) {
  hours.push({
    hour,
    offset: (hour - lowerLimitHour) * scaler,
  });
}

export default () => {
  const [showShadowLeft, setShowShadowLeft] = useState(false);
  const [showShadowRight, setShowShadowRight] = useState(true);
  const [selectedDay, setSelectedDay] = useState(0);
  const [timetable, setTimetable] = useState({});

  const scrollRef = useRef(null);

  const {
    allCoursesJson,
  } = useStaticQuery(coursesQuery);

  const timetableData = useMemo(() => {
    return allCoursesJson.edges.map(({ node }) => ({ ...node }))
  }, [allCoursesJson]);

  useEffect(() => {
    const orderedTimetable = {};

    Object.keys(days).forEach((day) => {
      orderedTimetable[day] = [];
      timetableData.filter((course) => course.day === day).forEach((course) => {
        orderedTimetable[day] = [
          ...orderedTimetable[day],
          course,
        ];
      });
    });

    setTimetable(orderedTimetable);
  }, []);

  const handleDayScroll = useCallback((e) => {
    setShowShadowLeft(e.target.scrollLeft > 0);
    setShowShadowRight(e.target.scrollWidth !== e.target.scrollLeft + e.target.clientWidth);
  }, []);

  const scrollToRight = useCallback(() => {
    scrollRef.current.scrollTo({
      left: scrollRef.current.scrollLeft + scrollRef.current.clientWidth,
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const scrollToLeft = useCallback(() => {
    scrollRef.current.scrollTo({
      left: scrollRef.current.scrollLeft - scrollRef.current.clientWidth,
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className="timetable-container">
      <div className="days-container">
        {
          Object.entries(timetable).map(([dayName]) => (
            <div key={uuid()} className="day-name">
              {dayTranslations[dayName]}
            </div>
          ))
        }
      </div>
      <div className="timetable">
        <div className="hours-tape">
          {
            hours.map(({ hour }) => (
              <p key={uuid()} className="hour">{hour}:00</p>
            ))
          }
        </div>
        <div className="day-rows-container">
          {
            showShadowLeft ? (
              <span className="shadow shadow-left" />
            ) : ''
          }
          {
            showShadowRight ? (
              <span className="shadow shadow-right" />
            ) : ''
          }
          {
            showShadowLeft ? (
              <ArrowButton onClick={scrollToLeft} className="arrow-button left" left />
            ) : ''
          }
          {
            showShadowRight ? (
              <ArrowButton onClick={scrollToRight} className="arrow-button right" right />
            ) : ''
          }
          <div ref={scrollRef} className={`day-rows ${showShadowLeft ? 'showShadowLeft' : ''} ${showShadowRight ? 'showShadowRight' : ''}`} onScroll={handleDayScroll}>
            {
              Object.entries(timetable).map(([day, courses]) => (
                <div key={uuid()} className="day-row">
                  {
                    hours.map(({ offset }) => {
                      const style = {
                        top: `${offset}%`,
                      };

                      return (
                        <span key={uuid()} className="hour-separator" style={style} />
                      );
                    })
                  }
                  <p className="day-name">{dayTranslations[day]}</p>
                  <div className="courses-for-day">
                    {
                      courses.map(({
                        id: courseID,
                        name,
                        background,
                        color,
                        location,
                        start,
                        stop,
                        width,
                        offset,
                        zindex,
                      }, index) => {
                        const length = getHourDifference(start, stop);
                        const topOffset = extractHoursFromText(start);

                        const styling = {
                          color,
                          backgroundColor: tinycolor(background).lighten(25).toString(),
                          borderLeft: `5px solid ${background}`,
                          height: `${length * scaler}%`,
                          top: `${(topOffset - lowerLimitHour) * scaler}%`,
                          left: `${offset}%`,
                          width: `${width}%`,
                          zIndex: zindex,
                        };

                        console.log(location);

                        return (
                          <div key={courseID} className="course" style={styling}>
                            <h2>{name}</h2>
                            {location && (
                              <h3>{location}</h3>
                            )}
                            <p className="hours">{start} - {stop}</p>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
};
